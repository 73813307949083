<template>
  <video
    controls
    class="aspect-video w-full self-start sm:rounded-2xl lg:rounded-none"
    preload="lazy"
    poster="https://res.cloudinary.com/klandestino-ab/video/upload/so_auto/v1711471402/djurensratt-se/video/STORA_framg%C3%A5ngar_f%C3%B6r_djuren_2023.jpg"
  >
    <source
      src="https://res.cloudinary.com/klandestino-ab/video/upload/so_auto/v1711471402/djurensratt-se/video/STORA_framg%C3%A5ngar_f%C3%B6r_djuren_2023"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
</template>
<style>
video::-webkit-media-controls-panel {
  background-image: none !important;
}
</style>
<script setup lang="ts"></script>
